import React, { useEffect, useState }  from 'react'
import { useNavigate } from 'react-router-dom';
import bannerContact from '../assets/images/banner-img2.jpg';

const ContactPage = () => {
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  
  const sendEmail = (event) => {
    event.preventDefault();

    // Obtén todos los campos requeridos en el formulario
    const camposRequeridos = document.querySelectorAll("[required]");

    // Variable para rastrear si todos los campos requeridos están completos
    let todosCompletos = true;

    // Verifica si todos los campos requeridos están completos
    camposRequeridos.forEach(function (campo) {
      if (!campo.value) {
        todosCompletos = false;
      }
    });

    if (!todosCompletos) {
      // Algunos campos requeridos están vacíos, muestra un mensaje de error
      alert("Please complete all required fields before submitting.");
    } else {
      // Todos los campos requeridos están completos, establece el estado de redirección en true
      setTimeout(() => {
        alert('Message sent successfully. Thank you for contacting us!');
        // Redirige al usuario a la URL deseada
        navigate('/');
      }, 2000);
    }
  };
  


  
  return (
    <>
    <section id='contact' className='md:px-12 p-4 max-w-screen-2xl mx-auto mt-20'>
    <div className='gradientBg rounded-xl md:p-9 px-4 py-9'>
    <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-10">
      {/* banner image*/}
      <div>
        <img src={bannerContact} alt="banner image" className="lg:h-[386px] rounded-md"/>
      </div>
      {/*banner content */}
      <div className="md:w-3/5">
        <h2 className="md:text-4xl text-3xl font-bold text-white mb-6 leading-relaxed">CONTACT</h2>
        <p className="text-[#EBEBEB] text-xl mb-8">Request your free consultation in the following form.<br/>
        We will contact you today!
        </p>
        <div className="space-x-5 space-y-4 flex flex-col ">
          <form id='contact-Form' className='space-x-5 space-y-4 flex flex-col'>
          <input type='text' placeholder='Name (your or your company)' className='w-80 h-10 mx-5 rounded  px-3' required></input>
          <input type='text' placeholder='Phone Number' className='w-80 h-10 rounded px-3' required></input>
          <input type='text' placeholder='E-mail (mail@web.com)' className='w-80 h-10  rounded px-3' required></input>
          <input type='text' placeholder='Whay do you need? Tell us...' className='w-80 h-10 rounded px-3' required></input>
          <input type='submit' className='btnPrimary w-60' value='SEND MESSAGE' onClick={sendEmail}></input>
          </form>
          
        </div>
      </div>
      
    </div>
    </div>
</section>



    </>
  );
};

export default ContactPage