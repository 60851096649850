import React from 'react'
import Home from '../components/Home'
import About from '../components/About'
import Services from '../components/Services'

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    window.scrollTo({
      top: section.offsetTop,
      behavior: "smooth", // Agrega una animación de desplazamiento suave
    });
  }
};

const HomePage = () => {
 
  return (
    <>
      <Home/>
      <About/>
      <Services/>
    </>
  )
}

export default HomePage;
