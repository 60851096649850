import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../assets/images/VIMAR.svg';
import { FaBars, FaTimes } from 'react-icons/fa';


const Navbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { link: "About", section: "about" },
    { link: "Services", section: "services" },
    { link: "Questions", section: "questions" },
  ];

  const handleNavigation2 = (section) => {
    if (location.pathname !== "/contact") {
      window.location.href = "/contact"; // Redirige primero a /contact
    } else {
      // Utiliza el objeto de estado de ubicación para pasar la información de la sección
      window.location.hash = `#${section}`;
      setIsMenuOpen(false); // Cierra el menú móvil después de hacer clic en un enlace
    }
  };
  

  const handleNavigation = (section) => {
    if (location.pathname !== "/") {
      window.location.href = "/"; // Redirige a la página de inicio si no estás en ella
    }

    // Utiliza el objeto de estado de ubicación para pasar la información de la sección
    window.location.href = `/#${section}`;
    setIsMenuOpen(false); // Cierra el menú móvil después de hacer clic en un enlace
  };

  return (
    <>
      <nav className="bg-white md:px-14 p-4 max-w-screen-100% mx-auto text-primary fixed top-0 right-0 left-0">
        <div className="text-lg container mx-auto flex justify-between items-center font-medium">
          <div className="flex space-x-14 items-center">
            <Link to="/">
              <img alt="Logo" src={logo} className="w-10 inline-block items-center" />
            </Link>

            <ul className="md:flex space-x-12 hidden">
              {navItems.map(({ link, section }) => (
                <a
                  key={link}
                  href={`#${section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(section);
                  }}
                  className="block hover:text-gray-300"
                >
                  {link}
                </a>
              ))}
              <a key="Contact" href="/contact#contact" 
              onClick={(e) => {
                e.preventDefault();
                handleNavigation2("contact");
              }}
              className="block hover:text-gray-300">
                Contact
              </a>
            </ul>
          </div>

          <div className="space-x-12 hidden md:flex items-center">
            <a href="/contact" className="bg-secondary py-2 px-4 transition-all duration-300 rounded hover:text-white hover:bg-indigo-600">
              Free consulting
            </a>
          </div>

          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white focus:outline-none focus:text-gray-300">
              {isMenuOpen ? <FaTimes className="w-6 h-6 text-primary" /> : <FaBars className="w-6 h-6 text-primary" />}
            </button>
          </div>
        </div>
      </nav>

      <div className={`space-y-4 px-4 pt-24 pb-5 bg-secondary text-xl ${isMenuOpen ? 'block fixed top-0 right-0 left-0' : 'hidden' }`}>
        {navItems.map(({ link, section }) => (
          <Link
            key={link}
            to="/"
            onClick={() => handleNavigation(section)}
            className="block hover:text-gray-300 cursor-pointer"
          >
            {link}
          </Link>
        ))}
        <Link to="/contact" onClick={() => setIsMenuOpen(false)} className="block hover:text-gray-300 cursor-pointer">
          Contact
        </Link>
      </div>
    </>
  );
};

export default Navbar;
