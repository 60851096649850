import React from "react";
import Navbar from "../components/Navbar";
import Marketing360 from "../components/Marketing360";
import Questions from "../components/Questions";
import Footer from "../shared/Footer";
import { Outlet } from "react-router-dom";


const PublicLayaut = () => {
  return (
    <div className="App">
    <Navbar></Navbar>
      <Outlet/>
    <Marketing360/>
    <Questions/>
    <Footer/>
  </div>
  );
}

export default PublicLayaut