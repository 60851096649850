import React from "react";
import logo from '../assets/images/VIMAR.svg'
import { Link } from "react-router-dom";

const Footer = () =>{
  

return(
  <div className="bg-[#010851] md:px-14 p-4 max-w-screen-100% mx-auto text-white">
      <div className="my-12 flex flex-col md:flex-row gap-10">
        <div className="md:w-1/2 space-y-8">
        <a href=""><img alt='Logo' src={logo} className="w-10 inline-block items-center"/></a>
          <p className="md:w-1/2">Tell us what stage your company is in and what digital marketing service you need.<br/>
          <br/>
We will contact you today!</p>
          
        </div>
        {/* footer navigations*/}
        <div className="md:w-1/2 flex flex-col md:flex-row flex-wrap justify-between gap-8 items-start">
          <div className="space-y-4 mt-5">
            <h4 className="text-xl">Platform</h4>
            <ul className="space-y-3">
              <a href="/contact#contact" className="block hover:text-gray-300">Contact</a>
              <a href="/#about" className="block hover:text-gray-300">About us</a>

            </ul>
          </div>
          <div className="space-y-4 mt-5">
            <h4 className="text-xl">Services</h4>
            <ul className="space-y-3">
              <a href="/seo" className="block hover:text-gray-300">SEO Positioning</a>
              <a href="/desingweb" className="block hover:text-gray-300">-Web Desing and Development</a>
              <a href="/digitaladvertising" className="block hover:text-gray-300">Digital Advertising</a>
            </ul>
          </div>
          <div className="space-y-4 mt-5">
            <h4 className="text-xl">Contact</h4>
            <ul className="space-y-3">
              <p className=" hover:text-gray-300">Email: info@vimardigital.com</p>

            </ul>
          </div>
        </div>
      </div>
      <hr/>

      <div>
        <p>@All rights reserved.</p>
      </div>
  </div>
 );
};

export default Footer;