import React from 'react'
import Banner from '../shared/Banner'
import { Link } from 'react-router-dom'
import InformationCard from '../shared/InformationCard'
import bannerWebDesing from '../assets/images/banner-img4.jpg'

const DesingWebPage = () => {

  return (
    <>
      <section id='desingweb' className='md:px-12 p-4 max-w-screen-2xl mx-auto mt-20'>
        <Banner
          banner={bannerWebDesing}
          heading="WEB DESIGN AND DEVELOPMENT: THAT GENERATE SALES"
          subheading="We love web design, you can spend all afternoon or even all week changing the border of a button. It’s fun and you can make a beautiful website, but… does it sell?

          Our web designs induce the user to do exactly what your business needs.
          
          It’s going to be pretty, we promise, but it’s going to sell, which is our main goal"
          btn="FREE CONSULTING"
        />
      </section>
      <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
      <h2 className="text-3xl text-primary font-bold lg:2.1/2 mb-10 mt-2 text-center ">WHAT MAKES US DIFFERENT FROM ANY OTHER DIGITAL<br/> MARKETING AGENCY?</h2>
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
        <InformationCard
          title="The Results: Focus."
          info="Your website can be short, long, red, yellow, with movement, with videos… You have an infinite number of options.

          Your website can be a success or a failure if you don’t have a clear objective: your website must be a sales channel."
        />
        <InformationCard
          title="UX/UI specialists."
          info="Measure what types of users are your target and the knowledge they may have of navigation.

          These are essential to develop a successful website in your industry. Design is important but the user experience is what makes money for your business."
        />

        <InformationCard
          title="Copywriting that converts."
          info="It’s super ugly to lead people to do exactly what you want….

          We have developed a copywriting system in which we generate interest and impact to the user, and they are left wanting to know more and they get that need that makes them cough up the dough."
        />
        <InformationCard
          title="SEO, the **** SEO."
          info="Yes, it’s a headache… but it works.

          We have experienced a 500% growth by going from second page to top 3.
          
          Your website will be perfectly optimized.
          
          We have no packages (with SEO or without SEO). Things are either done right or they are not."
        />
      </div>
    </section>
    <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto flex flex-col lg:flex-row justify-between items-start gap-10">
    <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Web Development</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>WEB DESIGN AND DEVELOPMENT: RESPONSIVE, UX/UI AND SEO FRENDLY</h6>
    <p className="text-base text-tartiary mb-2">Do you want to have a successful website?

You must stand out in your sector, find what makes you different so that your users are interested in your products or services.

Don’t worry: let’s go for it!</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want the Best Web</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">E-commerce</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>ONLINE SHOP</h6>
    <p className="text-base text-tartiary mb-2">How many times have you passed by a physical store, entered to look around and left without buying anything?

The same thing happens on the Internet, you have to have a conversion strategy. People are not going to buy from you just because you have an online store.

Let us help you.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want an Ecommerce</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Web applications</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>APK AND IOS, WE BUILD YOUR WEB APPLICATION</h6>
    <p className="text-base text-tartiary mb-2">There are businesses that require an application.

It is more accessible for your clients, you have recurring clients, you want to keep track of your business...

Having a shortcut on your phone's desktop makes things easier.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want an APK</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Sales Funnels</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>TRAFFIC, ATTRACTION AND CONVERSION STRATEGY</h6>
    <p className="text-base text-tartiary mb-2">This is the most important! Strategy, everything else is useless.

You must have an action plan from the moment you launch your website, the user's journey, bottlenecks and a hundred etcetera.

Let's create a digital strategy.

This gets interesting!</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">Digital Strategy</a>
    </div>
  </div>

</section>


    </>
  )
}

export default DesingWebPage