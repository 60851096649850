import React from "react";
import { Link } from "react-router-dom";

const Banner = ({banner, heading, subheading, btn}) => {
  return(
      <div className="gradientBg rounded-xl md:p-9 px-4 py-9">
          <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-10">
            {/* banner image*/}
            <div>
              <img src={banner} alt="banner image" className="lg:h-[386px] rounded-md"/>
            </div>
            {/*banner content */}
            <div className="md:w-3/5">
              <h2 className="md:text-4xl text-3xl font-bold text-white mb-6 leading-relaxed">{heading}</h2>
              <p className="text-[#EBEBEB] text-xl mb-8">{subheading}</p>
              <div className="space-x-5 space-y-4">
                <Link to="/contact" className="btnPrimary">{btn}</Link>
              </div>
            </div>
            
          </div>
        </div>
  );
};

export default Banner;
