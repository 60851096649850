import React from "react";
import InformationCard from "../shared/InformationCard";

const About = () => {
  return (
    <div id="about" className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
      <h2 className="text-3xl text-primary font-bold lg:2.1/2 mb-10 mt-2 text-center ">WHAT MAKES US DIFFERENT FROM ANY OTHER DIGITAL<br/> MARKETING AGENCY?</h2>
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
        <InformationCard
          title="Our Clients Endorse Us."
          info="You may be wondering… how can they say all this with their heads held so high? Because our track record has generated business for both large and small companies and we are sure we can help you too."
        />
        <InformationCard
          title="Not All Businesses are the Same."
          info="It would be very easy to tell you the ingredients of the perfect business, but this is not the reality. The reality is that each business requires a different analysis and marketing plan.

          Maybe SEO works for your neighbor, but you will never get a return on your investment by doing SEO
          .Your business probably needs a customized plan, don’t worry, we are experts in this."
        />

        <InformationCard
          title="The Truth Hurts, but it Wakes You Up."
          info="It would be wonderful if we always told the same speech, if you end up positioning here… if you advertise on this keyword… your website is not bad you could… No, a resounding no!

          If your website is a disaster we will tell you, we don’t want to make you feel bad, far from it.
          
          If something is not working we will tell you and we will get down to work to improve the situation. We will focus on what’s important: selling!"
        />
        <InformationCard
          title="We Have Already Been in That Phase."
          info="We know firsthand all phases of a business, we have worked with freelancers with a turnover of € 1,500 and with companies with a turnover of more than € 5,000,000.

          We know at each stage of development what are the most efficient decisions to scale your business.
          
          Tell us what stage you are in and we will evaluate how we can help you."
        />
      </div>
    </div>
  );
};

export default About;