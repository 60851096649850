import React from "react";
import Banner from "../shared/Banner";
import bannerHome from "../assets/images/banner-img5.jpg"

const Home = () => {
  return(
    <>
      <div className="md:px-12 p-4 max-w-screen-100% mx-auto mt-20">
        <Banner banner={bannerHome}
        heading="DIGITAL MARKETING TO CREATE BUSINESS, NOT TO INCREASE RATIOS"
        subheading="Our priority is not to position you here or give you visibility there. Our priority is to make you bill more. We know that money is important but, there is something much more important: time, and we don’t want to waste it with you, and we don’t want you to waste it with us. If you want us to evaluate how we can help you generate more business, click on the button. Come on, the first consultation is on us."
        btn={'FREE CONSULTING'}
        />
      </div>
    </>
  );
}

export default Home;