import React from 'react'
import Banner from '../shared/Banner';
import InformationCard from '../shared/InformationCard';
import bannerseo from '../assets/images/banner-img1.jpg'

const SeoPage = () => {
  return (
    <>
      <section id='seopage' className='md:px-12 p-4 max-w-screen-2xl mx-auto mt-20'>
        <Banner
          banner={bannerseo}
          heading="SEO POSITIONING TO SELL MORE, NOT TO HAVE MORE TRAFFIC"
          subheading="SEO Positioning is not about appearing everywhere without meaning, you must have a strategy focused on generating sales.

          You don't care about having 1,000, 10,000 or 100,000 organic visits if those visits do not bring you sales or business.
          
          If you are receiving traffic and not selling... it's time to change your SEO strategy."
          btn="FREE AUDIT"
        />
      </section>
      <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
      <h2 className="text-3xl text-primary font-bold lg:2.1/2 mb-10 mt-2 text-center ">WHAT MAKES US DIFFERENT FROM ANY OTHER DIGITAL<br/> MARKETING AGENCY?</h2>
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
        <InformationCard
          title="We are not going to sell you the motorcycle."
          info="Why are we going to show you a pretty graph that only goes up and up but your wallet and budget only goes down.

          The beauty of SEO is being where you need to be, and with little effort reaching clients interested in your business."
        />
        <InformationCard
          title="SEO costs money."
          info="If any SEO consultant or SEO Agency tells you that positioning yourself on Google is free or cheap... I'm sorry or they are scamming you or they don't know what they are saying.

          Being first, second or third on Google gives a lot of money, and it is not free at all."
        />

        <InformationCard
          title="We want you to Make money."
          info="Our satisfaction is to report sales or leads to you and that this means more money for your business.

          We don't want to position you on: What is a pillow? No, that page doesn't bring money, let's go for Buy a pillow.
          
          We know it's more difficult, but it brings money."
        />
        <InformationCard
          title="A long-term strategy."
          info="Do you bet on your business?

          Well, invest in SEO to have authority over your niche or topic in the future.
          
          SEM is good and of course it generates money, but being positioned will give a very good image to your brand, you will give confidence and sales will be much easier."
        />
      </div>
    </section>
    <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto flex flex-col lg:flex-row justify-between items-start gap-10">
  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">SEO Audit</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>DETECTION OF CONTENT, STRUCTURE OR WEB OPTIMIZATION PROBLEMS</h6>
    <p className="text-base text-tartiary mb-2">In SEO does not count one thing, it counts EVERYTHING.

SEO in the XXI century is practically an art, you have to be detailed in all aspects of the web to be liked by Google.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want my website to be ready</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Linkbuilding</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>LINKS, MENTIONS AND WEB AUTHORITY</h6>
    <p className="text-base text-tartiary mb-2">How many times have you passed by a physical store, entered to look around and left without buying anything?

The same thing happens on the Internet, you have to have a conversion strategy. People are not going to buy from you just because you have an online store.

Let us help you.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">Linkbuilding Strategy</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Local SEO</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>GOOGLE MAPS AND SEO BY ZONES</h6>
    <p className="text-base text-tartiary mb-2">There are businesses that require an application.

It’s more accessible for your customers, you have recurring customers, you want to keep track of your business…

Having a shortcut on the desktop of your phone makes things easier.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">Local SEO Positioning</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">SEO Agency</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>COMPLETE SEO STRATEGY, WE WILL NOT BE A SUPPLIER, WE WILL BE PART OF YOUR TEAM.</h6>
    <p className="text-base text-tartiary mb-2">If you don’t want to lose your bottom line, you have to go out every day to run.

It’s the same with SEO, if you want to stay on top you have to keep working. As soon as you get careless, someone else can come along and snatch your position.

If you are stuck in your growth or need to start from scratch this is your best option.</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want my SEO team</a>
    </div>
  </div>

</section>
    </>
  )
}

export default SeoPage;