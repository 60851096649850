import React from "react";
import image1 from '../assets/images/diseno-web-1.webp'
import image2 from '../assets/images/service-social-media.webp'
import image3 from '../assets/images/service-seo.webp'
import { Link } from "react-router-dom";

const Services = () => {
  return(
    <div id="services" className="md:px-14 p-4 max-w-s mx-auto space-y-8">
      <div className="flex flex-col md:flex-row justify-between items-center gap-8">
        <div className="md:w-1/2">
          <img src={image1}/>
        </div>

        {/* info services*/}
        <div className="md:w-2/5">
          <h2 className="md:text-5xl text-3xl font-bold text-primary leading-normal">Web Design and Development</h2>
          <h3 className="md:text-3xl text-xl font-semibold my-4 text-secondary leading-normal">CREATION OF WEB PAGES AND MOBILE APPLICATIONS</h3>
          <p className="text-tartiary text-lg mb-7">Yes, we’re going to make your website beautiful, don’t worry.

But let’s really focus on what’s important… Let’s create a website that sells!

If possible the best in your sector.</p>
        <a href="/desingweb#desingweb" className="btnPrimary">I WANT AN AMAGING WEBSITE</a>
        </div>
      </div>

      {/*2nd part */}
      <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-8">
        <div className="md:w-1/2">
          <img src={image2}/>
        </div>

        {/* info services*/}
        <div className="md:w-2/5">
          <h2 className="md:text-5xl text-3xl font-bold text-primary leading-normal">Digital Advertising</h2>
          <h3 className="md:text-3xl text-xl font-semibold my-4 text-secondary leading-normal">ADVERTISING ON GOOGLE, FACEBOOK, INTAGRAM, LINKEDIN AND TIKTOK</h3>
          <p className="text-tartiary text-lg mb-7">KPI’s, CPC, Impressions, Clicks… all these terms. Do they really matter to you?

If you want them you’ll get your monthly reports, but let’s be clear on how much money you’re putting in and how much revenue it’s bringing in.

Sorry… we like to be clear.</p>
        <a href="/digitaladvertising#digitaladvertising" className="btnPrimary">ADVERTISE MY COMPANY</a>
        </div>
      </div>

      {/*3er part*/}
      <div className="flex flex-col md:flex-row justify-between items-center gap-8">
        <div className="md:w-1/2">
          <img src={image3}/>
        </div>

        {/* info services*/}
        <div className="md:w-2/5">
          <h2 className="md:text-5xl text-3xl font-bold text-primary leading-normal">SEO Positioning</h2>
          <h3 className="md:text-3xl text-xl font-semibold my-4 text-secondary leading-normal">POSITION YOUR BUSINESS ABOVE YOUR COMPETITORS</h3>
          <p className="text-tartiary text-lg mb-7">Your site is receiving thousands of visits but the turnover is not according to the visits that are coming in? Great, they have positioned you where it was easier and did not bring any benefit, just a nice graph and a green report. We are not going to give you a lot of traffic if it doesn’t make sense. Please, transactional searches… what’s the point of ranking for “What’s a dog collar”? Yeah, and we’ve seen worse.</p>
        <a href="/seo/#seopage" className="btnPrimary">POSITIONING MY COMPANY IN GOOGLE</a>
        </div>
      </div>
    </div>
  );
};

export default Services;