import React from "react";

const Questions = () =>{
  return(
    <div id="questions" className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
      <div className="bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-auto shadow-2xl px-10 lg:w-1/2">
          <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Why hire us?</h3>
          <p className="text-base text-tartiary mb-2">When you hire us, you are not hiring a SEO, SEM, Web Designer, Social Media Manager…<br/>
          <br/>
You are hiring a complete team that will be responsible for the development of your digital strategy.<br/>
<br/>
We will increase your visibility on the Internet and your turnover, creating an irresistible offer for your sector.</p>
        </div>
        <div className="bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-auto shadow-2xl px-10 lg:w-1/2">
          <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">How do we increase your turnover?</h3>
          <p className="text-base text-tartiary mb-2">The first thing is to analyze your business and your<br/> competition and see where you can be more competitive<br/> to create the best offer for your niche market.<br/>
<br/>
We will apply the strategy that best suits your business.<br/> The main thing is to make yourself visible on the Internet<br/> and have a good offer, your sales team will do the rest.</p>
        </div>
        
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10 mt-14">
      <div className="bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-auto shadow-2xl px-10 lg:w-1/2">
          <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">What is our strategy?</h3>
          <p className="text-base text-tartiary mb-2">We do not always use the same strategy.<br/>
          <br/>
          A prior market analysis will make us decide to make the best decision to provide your business with the best short-term results while we work on a long-term authority and positioning strategy.<br/>
          <br/>
          Tell us what you have in mind, we listen to you.</p>
        </div>
        <div className="bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-auto shadow-2xl px-10 lg:w-1/2">
          <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Where do we offer our services?</h3>
          <p className="text-base text-tartiary mb-2">Expiey is based in Madrid, Valencia and Barcelona (Spain) and in EEUU. However, we offer our services worldwide.<br/>
<br/>
We have clients in JAPON, INDIA, UAE, ETC.<br/>
<br/>
Distance does not limit us.</p>
        </div>
        
      </div>
    </div>
  );
};

export default Questions;