import React from "react";
import startIcon from "../assets/images/icons8-estrella-48.png"
import {AiOutlinePlusCircle} from 'react-icons/ai'
import { Link } from "react-router-dom";

import {FaEquals} from 'react-icons/fa'


const Marketing360 = () => {
  return(
    <div className="md:px-12 p-4 max-w-screen-lg mx-auto mt-20">
      <div className="gradientBg2 rounded-xl md:p-9 px-4 py-9">
        <div className="flex flex-col  justify-between items-center gap-5">
          <img src={startIcon}/>
          <h3 className="text-[#EBEBEB]">OUR STAR SERVICE:</h3>
          <h2 className="md:text-5xl text-3xl font-bold text-[#EBEBEB] leading-normal">MARKETING 360°</h2>
          <p className="text-[#EBEBEB] text-xl mb-8 px-10">Do you want to let go of<br/> the controls? Do you want to know the concept of “delegating”? Take care of your business, your suppliers, employees and spend time<br/> with your family, whatever you want. We will take care of everything digital and we will inform you every week of the improvements achieved. Don’t worry, you are in<br/> good hands! Just ask our latest client.</p>
        </div>
        <div className="flex items-center justify-between px-10">
        <div>
          <div>
          <div className="flex items-center ">
          <AiOutlinePlusCircle className="text-red-50 mx-3"></AiOutlinePlusCircle>
          <p className="text-red-50">WEB DESING AND DEVELOPMENT</p>
        </div>
        <div className="flex items-center">
          <AiOutlinePlusCircle className="text-red-50 mx-3"></AiOutlinePlusCircle>
          <p className="text-red-50">DIGITAL ADVERTISING</p>
        </div>
        <div className="flex items-center ">
          <AiOutlinePlusCircle className="text-red-50 mx-3"></AiOutlinePlusCircle>
          <p className="text-red-50">SEO POSITIONING</p>
        </div>
          </div>
        </div>
        <FaEquals className="text-red-50 text-3xl mr-14"></FaEquals>
        <div>
          <h2 className="md:text-2xl text-xl font-bold text-[#EBEBEB] leading-normal">MARKETING 360°</h2>
        </div>
        </div>
          <div className="flex justify-center">
          <a href="/contact" className="btnPrimary mt-8 ">I WANT TO DELEGATE DIGITAL MARKETING</a>  
          </div>      
      </div>

    </div>
  );
};

export default Marketing360;