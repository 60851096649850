import { createBrowserRouter } from "react-router-dom";
import PublicLayaut from "../pages/PublicLayaut";
import SeoPage from "../pages/SeoPage";
import DigitalAdvertisingPage from "../pages/DigitalAdvertisingPage";
import DesingWebPage from "../pages/DesingWebPage";
import ContactPage from "../pages/ContactPage";
import HomePage from "../pages/HomePage";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayaut />,
    children: [
      { path: "/", element: <HomePage /> }, // Página de inicio como una ruta secundaria
      { path: "/seo", element: <SeoPage /> },
      { path: "/digitaladvertising", element: <DigitalAdvertisingPage /> },
      { path: "/desingweb", element: <DesingWebPage /> },
      { path: "/contact", element: <ContactPage /> },
    ],
  },
]);

export default AppRouter;