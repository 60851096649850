import React from 'react'
import Banner from '../shared/Banner'
import InformationCard from '../shared/InformationCard'
import bannerDigitalAd from '../assets/images/banner-img3.jpg'
import { Link } from 'react-router-dom';

const DigitalAdvertisingPage = () => {
  return (
    <>
      <section id='digitaladvertising' className='md:px-12 p-4 max-w-screen-2xl mx-auto mt-20'>
        <Banner
          banner={bannerDigitalAd}
          heading="DIGITAL ADVERTISING: DEVELOPMENT OF A PROFITABLE PAYMENT STRATEGY"
          subheading="Do you already know which is your perfect digital channel?

          Being on Google advertising is a great window for leads, but Instagram you should also…
          
          Have you seen the ads on TikTok? What a crazy thing, 100 leads for that price…
          
          You don't want to do everything, we are going to analyze what your ideal sales channel is and we will focus the budget on making you generate more money."
          btn="FREE AUDIT"
        />
      </section>
      <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto">
      <h2 className="text-3xl text-primary font-bold lg:2.1/2 mb-10 mt-2 text-center ">WHAT MAKES US DIFFERENT FROM ANY OTHER DIGITAL<br/> MARKETING AGENCY?</h2>
      <div className="flex flex-col lg:flex-row justify-between items-start gap-10">
        <InformationCard
          title="The Objective is to Sell."
          info="There is no point in receiving impressions, clicks or talking about CTR. Our main objective is the ROI of the investment. 

          If you invest €100, multiply it to €1,000 and if you invest €1,000, multiply it to €10,000."
        />
        <InformationCard
          title="We have tried EVERYTHING."
          info="We know perfectly well how advertising works on Facebook, Tiktok, Instagra, LinkedIn, Google Ads...

          We have the perfect strategy for each tool.
          
          Let us show you."
        />

        <InformationCard
          title="Google Wants Your Money."
          info="Well... Google, Facebook (Meta), Tiktok... It doesn't matter, they are companies and as businesses they want to invoice.

          Don't believe everything the tools offer, they are the first ones interested in you using them, but be careful."
        />
        <InformationCard
          title="Instant Results."
          info="The positive thing about paid advertising is that in the first month we will see if your business is profitable.

          We are going to test the first month, once we find the key, we will scale until we achieve a profitability that you have never seen"
        />
      </div>
    </section>
    <section className="my-24 md:px-14 px-4 max-w-screen-2xl mx-auto flex flex-col lg:flex-row justify-between items-start gap-10">
    <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Google ADS</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>MANAGEMENT, OPTIMIZATION AND PRIORITIZATION</h6>
    <p className="text-base text-tartiary mb-2">In a Google ADS campaign it matters more where you send the user to than the ad itself.<br/>
    <br/>
We will have the architecture of your website ready to convert with ads that go to the neck</p>
    <div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want Google Ads</a>
    </div>
  </div>

 
<div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
  <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">Facebook Ads</h3>
  <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>SEGMENTATION, INTERESTS AND AUDIENCE SCALING</h6>
  <p className="text-base text-tartiary mb-2">Facebook is our ally in this case, we will check which is your target audience and the perfect customer for your business.<br/>
    <br/>
    With good advertising impacts, customers will come looking for you instead of you looking for them.</p>
  <div className="space-x-5 space-y-4 my-10">
    <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want Facebook Ads</a> {/* Asegúrate de usar la ruta completa, por ejemplo, "/contact" */}
  </div>
</div>



  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">LinkedIn ADS</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>MESSAGING, PUBLISHING AND B2B CAMPAIGNS</h6>
    <p className="text-base text-tartiary mb-2">Is your business a B2B service?
<br/>
<br/>
Great, there are customers for everything on the Internet. You just have to choose the right approach.
<br/>
<br/>
Let’s create a LinkedIn strategy that will increase your revenue</p>
<div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want LinkedIn Ads</a>
    </div>
  </div>

  <div className="h-auto px-8 lg:w-1/4 hover:bg-blue-200 hover:border-l-4 border-primary transition duration-300 ease-in-out">
    <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">TikTok ADS</h3>
    <h6 className='text-xl text-primary font-bold lg:2.1/2 mb-3 mt-2'>VIRALIZE YOUR BUSINESS</h6>
    <p className="text-base text-tartiary mb-2">We thought so too: in TikTok there are only dances.
    <br/>
<br/>
What a face we had when we had a x27 ROI on our first campaign.
<br/>
<br/>
Never underestimate any tool, maybe it’s where your customers are.</p>
<div className="space-x-5 space-y-4 my-10">
      <a href="/contact#contact" className="btnPrimary text-base flex text-center">I want TikTok Ads</a>
    </div>
  </div>
</section>
    </>
  )
}

export default DigitalAdvertisingPage