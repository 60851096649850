import React from "react";
import { text } from "svelte/internal";

const InformationCard = ({title, info}) => {
  return(
    <div className="bg-[rgba(255, 255, 255, 0.04)] rounded-[35px] h-auto shadow-2xl px-8 lg:w-1/4">
          <h3 className="text-3xl text-primary font-bold lg:2.1/2 mb-3 mt-2">{title}</h3>
          <p className="text-base text-tartiary mb-2">{info}</p>
        </div>
  );
};

export default InformationCard;